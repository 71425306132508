import { Location } from '@eo-storefronts/eo-core'
import ViewedRecentlyItem from '~/src/components/marketplace/viewed-recently/viewed-recently-item'
import ScrollableList from '~/src/components/mui-wrappers/scrollable-list'
import ScrollableListItem from '~/src/components/mui-wrappers/scrollable-list/scrollable-list-item'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

interface Props {
  items: Location[],
}

const ViewedRecentlyItems = ({ items }: Props) => {
  const muiTheme = useCustomMuiTheme()

  return (
    <ScrollableList orientation='horizontal'>
      {items.map((location: Location, index: number) =>
        <ScrollableListItem
          key={`${index}-viewed-recently-item-${location.uuid}`}
          index={index}
          sx={{
            [muiTheme.breakpoints.down('md')]: {
              pl: 0,
              pr: 1
            }
          }}
        >
          <ViewedRecentlyItem item={location} />
        </ScrollableListItem>
      )}
    </ScrollableList>
  )
}

export default ViewedRecentlyItems

import { Location } from '@eo-storefronts/eo-core'
import { Box } from '@mui/material'
import ViewedRecentlyItems from '~/src/components/marketplace/viewed-recently/viewed-recently-items'
import Title from '~/src/components/mui-wrappers/Title'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { MARKETPLACE_VIEWED_RECENTLY_STATE } from '~/src/stores/marketplace/viewed-recently'

const ViewedRecently = () => {
  const { t } = useTranslations()
  const items: Location[] = useEoValue(MARKETPLACE_VIEWED_RECENTLY_STATE)

  if (!items?.length) {
    return null
  }

  return (
    <Box>
      <Title
        variant='h5'
        sx={{
          fontWeight: 'normal',
          mb: 2
        }}
      >
        {t('explorer.viewed_recently')}
      </Title>

      <ViewedRecentlyItems items={items} />
    </Box>
  )
}

export default ViewedRecently

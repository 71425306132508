import { NewsFeed } from '@eo-storefronts/eo-core'
import ExplorerNewsItem from '~/src/components/marketplace/news/explorer-news-item'
import { Box } from '@mui/material'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

interface Props {
  items: NewsFeed[],
}

const ExplorerNewItemsMobile = ({ items }: Props) => {
  const muiTheme = useCustomMuiTheme()

  return (
    <Box
      sx={{
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'space-between',
        width: '100%',
        overflow: 'hidden',
        gap: 2,
        [muiTheme.breakpoints.down('md')]: {
          flexDirection: 'column',
          justifyContent: 'center'
        }
      }}
    >
      {items.map((item: NewsFeed, index: number) => (
        <ExplorerNewsItem
          key={index}
          news={item}
        />
      ))}
    </Box>
  )
}

export default ExplorerNewItemsMobile

import { Box, Theme, useMediaQuery } from '@mui/material'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { useEoValue } from '~/src/hooks/useEoState'
import { MARKETPLACE_EXPLORER_NEWS_SELECTOR_FAMILY } from '~/src/stores/news'
import { NewsFeed } from '@eo-storefronts/eo-core'
import Title from '~/src/components/mui-wrappers/Title'
import { useTranslations } from '~/src/hooks/useTranslations'
import { LAYOUT_STATE } from '~/src/stores/layout'
import ExplorerNewItemsMobile from '~/src/components/marketplace/news/explorer-new-items-mobile'
import ExplorerNewItemsDesktop from '~/src/components/marketplace/news/explorer-new-items-desktop'

const ExplorerNewsItemsContainer = () => {
  const { t } = useTranslations()
  const muiTheme = useCustomMuiTheme()
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const { isMobile } = useEoValue(LAYOUT_STATE)
  const items: NewsFeed[] = useEoValue(MARKETPLACE_EXPLORER_NEWS_SELECTOR_FAMILY(downMd))

  if (!items?.length) {
    return null
  }

  return (
    <Box>
      <Title
        variant='h5'
        sx={{
          fontWeight: 'normal',
          mb: 2,
          [muiTheme.breakpoints.down('md')]: {
            pl: 3
          }
        }}
      >
        {t('explorer.news.title')}
      </Title>

      {(isMobile && downMd)
        ? <ExplorerNewItemsMobile items={items}/>
        : <ExplorerNewItemsDesktop items={items}/>}
    </Box>
  )
}

export default ExplorerNewsItemsContainer

import StylesExplorerServiceInterface from '~/src/pages/explorer/styles/StylesExplorerServiceInterface'
import StylesExplorerServiceStyleThree from '~/src/pages/explorer/styles/StylesExplorerServiceStyleThree'
import StylesExplorerServiceStyleTwo from '~/src/pages/explorer/styles/StylesExplorerServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getExplorerTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesExplorerServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesExplorerServiceStyleTwo(muiTheme)
    case TemplateEnum.STYLE_THREE:
      return new StylesExplorerServiceStyleThree(muiTheme)
    default:
      return new StylesExplorerServiceStyleTwo(muiTheme)
  }
}

export default getExplorerTemplateService

import { Location } from '@eo-storefronts/eo-core'
import LocationCard from '~/src/components/marketplace/locations/location-card'
import LocationResponsiveDialog from '~/src/components/marketplace/locations/location-responsive-dialog'

interface Props {
  item: Location,
}

const ViewedRecentlyItem = ({ item }: Props) => {
  if (!item?.id) {
    return null
  }

  return (
    <LocationResponsiveDialog
      id={item.id}
      activator={(
        <LocationCard
          location={item}
          displayFilters
          sx={{
            '&:hover': {
              cursor: 'pointer'
            }
          }}
        />
      )}
    />
  )
}

export default ViewedRecentlyItem

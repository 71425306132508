import { Box } from '@mui/material'
import ExplorerNewsItemsContainer from '~/src/components/marketplace/news/explorer-news-items-container'
import Suggestions from '~/src/components/marketplace/suggestions'
import ViewedRecently from '~/src/components/marketplace/viewed-recently'
import useGetExplorerTemplate from '~/src/pages/explorer/styles/useGetExplorerTemplate'
import DocumentTitleInjector from '~/src/components/root/DocumentTitleInjector'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import RoutesEnum from '~/src/router/enums/routes.enum'
import SlideFromRight from '~/src/components/mui-wrappers/animations/motion/SlideFromRight'

const Explorer = () => {
  const pageName: keyof typeof RoutesEnum = 'EXPLORER'
  const muiTheme = useCustomMuiTheme()

  useGetExplorerTemplate()

  return (
    <SlideFromRight
      pathname={pageName}
      transition={{
        duration: .5
      }}
    >
      <DocumentTitleInjector pageName={pageName}/>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
          pt: 2,
          [muiTheme.breakpoints.down('md')]: {
            gap: 2
          }
        }}
      >
        <Box
          sx={{
            [muiTheme.breakpoints.down('md')]: {
              pl: 3
            }
          }}
        >
          <ViewedRecently />
        </Box>

        <ExplorerNewsItemsContainer />

        <Box
          sx={{
            [muiTheme.breakpoints.down('md')]: {
              pl: 3
            }
          }}
        >
          <Suggestions />
        </Box>
      </Box>
    </SlideFromRight>
  )
}

export default Explorer

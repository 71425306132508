import getExplorerTemplateService from '~/src/pages/explorer/styles/GetExplorerTemplateService'
import StylesExplorerServiceInterface from '~/src/pages/explorer/styles/StylesExplorerServiceInterface'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'
import { useLayout } from '~/src/hooks/layout/useLayout'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

const useGetExplorerTemplate = (): StylesExplorerServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle('explorer')

  useLayout('explorer')

  return getExplorerTemplateService(pageStyle, muiTheme)
}

export default useGetExplorerTemplate

import { SxProps } from '@mui/material'
import StylesLocatorServiceInterface from '~/src/pages/locator/styles/StylesLocatorServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesExplorerServiceStyleTwo extends TemplateServiceStyleBase implements StylesLocatorServiceInterface {
  public getPageSx(): SxProps {
    return {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 50%)',
      gridTemplateAreas: '"left right"',
      gridTemplateRows: 'auto',
      [this.muiTheme.breakpoints.only('xs')]: {
        gridTemplateColumns: 'auto',
        gridTemplateRows: 'auto auto',
        gridTemplateAreas: '"right" "left"'
      }
    }
  }

  public getContentRightSx(): SxProps {
    return {
      gridArea: 'right',
      img: {
        position: 'fixed',
        objectFit: 'cover',
        objectPosition: 'bottom',
        minWidth: '50%',
        height: 'var(--page-height)'
      },
      [this.muiTheme.breakpoints.only('xs')]: {
        maxHeight: '200px',
        overflow: 'hidden',
        img: {
          position: 'relative',
          maxWidth: '100%',
          objectFit: 'cover'
        }
      }
    }
  }

  public getContentLeftSx(): SxProps {
    return {
      gridArea: 'left',
      p: 4
    }
  }
}

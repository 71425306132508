import { NewsFeed } from '@eo-storefronts/eo-core'
import ScrollableListItem from '~/src/components/mui-wrappers/scrollable-list/scrollable-list-item'
import ExplorerNewsItem from '~/src/components/marketplace/news/explorer-news-item'
import ScrollableList from '~/src/components/mui-wrappers/scrollable-list'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

interface Props {
  items: NewsFeed[],
}

const ExplorerNewItemsDesktop = ({ items }: Props) => {
  const muiTheme = useCustomMuiTheme()

  return (
    <ScrollableList
      draggable
      className='eo-hide-scrollbar'
      orientation='horizontal'
      IndicatorProps={{
        sx: {
          top: 'calc(50% - 1.5rem)'
        }
      }}
      sx={{
        scrollBehavior: 'smooth',
        scrollSnapType: 'x mandatory',
        py: 2,
        px: 1,
        [muiTheme.breakpoints.up('md')]: {
          scrollPaddingLeft: muiTheme.spacing(1),
          width: `calc(100% + ${muiTheme.spacing(2)})`,
          ml: -1
        },
        [muiTheme.breakpoints.down('md')]: {
          px: 2
        }
      }}
    >
      {items.map((item: NewsFeed, index: number) => (
        <ScrollableListItem
          key={index}
          index={index}
          sx={{
            scrollSnapAlign: 'start',
            flexShrink: '0',
            flexGrow: '0',
            flexBasis: `calc(33.3333% - (${muiTheme.spacing(1.5)} - 1px))`,
            [muiTheme.breakpoints.down('xl')]: {
              flexBasis: `calc(50% - ${muiTheme.spacing(1)})`
            },
            [muiTheme.breakpoints.down('md')]: {
              flexBasis: `calc(50% - ${muiTheme.spacing(1)})`,
              scrollMarginLeft: muiTheme.spacing(2)
            }
          }}
        >
          <ExplorerNewsItem news={item}/>
        </ScrollableListItem>
      ))}
    </ScrollableList>
  )
}

export default ExplorerNewItemsDesktop
